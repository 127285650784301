<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">营销活动</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">邀请好友赢奖励</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">活动明细</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="display:flex;flex-direction: column;align-items: start;">
          <div class="searchbox">
            <div title="被邀请人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">被邀请人姓名:</span>
              <el-input
                v-model="inviteeUserName"
                type="text"
                size="small"
                placeholder="请输入被邀请人姓名"
                clearable
              />
            </div>
            <div title="被邀请人手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">被邀请人手机号:</span>
              <el-input
                v-model="inviteeUserPhone"
                type="text"
                size="small"
                placeholder="请输入被邀请人手机号"
                clearable
              />
            </div>
            <div title="邀请人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">邀请人姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入邀请人姓名"
                clearable
              />
            </div>
            <div title="邀请人手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">邀请人手机号:</span>
              <el-input
                v-model="userPhone"
                type="text"
                size="small"
                placeholder="请输入邀请人手机号"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-top:15px;">
            <div title="是否入班" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">是否入班:</span>
              <el-select
                v-model="inClass"
                clearable
                placeholder="请选择是否入班"
                size="small"
              >
                <el-option
                  v-for="item in inClassList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否完成培训" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">是否完成培训:</span>
              <el-select
                v-model="finished"
                clearable
                placeholder="请选择是否完成培训"
                size="small"
              >
                <el-option
                  v-for="item in finishedList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="被邀请人姓名"
                fixed
                align="left"
                prop="inviteeUserName"
                show-overflow-tooltip
              />
              <el-table-column
                label="被邀请人手机号"
                align="left"
                prop="inviteeUserPhone"
                show-overflow-tooltip
              />
              <el-table-column
                label="是否入班"
                align="left"
                prop="inClass"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                  {{scope.row.inClass ? '是': '否'}}
              </template>
              </el-table-column>
              <el-table-column
                label="是否完成培训"
                align="left"
                prop="finished"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                  {{scope.row.finished ? '是': '否'}}
              </template>
              </el-table-column>
              <el-table-column
                label="被邀请人获得积分"
                align="left"
                prop="inviteePoint"
                show-overflow-tooltip
              />
              <el-table-column
                label="邀请人姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
              />
              <el-table-column
                label="邀请人手机号"
                align="left"
                prop="userPhone"
                show-overflow-tooltip
              />
              <el-table-column
                label="邀请人获得积分"
                align="left"
                prop="inviterPoint"
                show-overflow-tooltip
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ActivityDetails",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      inviteeUserName: "",
      inviteeUserPhone: "",
      userName: "",
      userPhone:'',
     inClass: "",
       finished:'',
     finishedList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
     inClassList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
    };
  },
  computed: {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        invitedId:this.$route.query.invitedId,
        inClass: this.inClass,
finished: this.finished,
      };
      if (this.inviteeUserName) {
          params.inviteeUserName = this.inviteeUserName;
      }
      if (this.inviteeUserPhone) {
          params.inviteeUserPhone = this.inviteeUserPhone;
      }
      if (this.userName) {
          params.userName = this.userName;
      }
      if (this.userPhone) {
          params.userPhone = this.userPhone;
      }
      this.doFetch({
        url: "/invited/act-invited/detailsPageList",
        params,
        pageNum,
      },true,2);
    },
    /* 创建活动 */
    CreateActivity() {

    },
    /* 活动明细 */
    ActivityDetails() {
        this.$router.push({
            path:'/web/ActivityDetails',
            // query:{

            // }
        })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 30;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped></style>
